import './App.css';
import './assets/bootstrap.min.css'
import { Parallax } from 'react-parallax';
import cover from './assets/cover.jpg'
import builtWith from './assets/builtwith.png'
import types from './types'
import references from './references'
import { Divider, Accordion, AccordionHeader, AccordionItem, AccordionPanel, FluentProvider, webLightTheme } from "@fluentui/react-components";

function App() {
  return (
    <div className="App">
      <Parallax strength={600} bgImage={cover}>
        <div className='content'>
          <div className='container mt-5'>
            <div className='d-flex justify-content-center'>
              <div className='bg-white p-5 rounded-3 marginTopCover'>
                <h1 className='d-flex justify-content-center'>Unit 16 - Assignment 1</h1>
                <h6 className='d-flex justify-content-center'>Harvey Street | DC40052400</h6>
                <h6 className="d-flex justify-content-center fontSizeSmall">(Scroll down to see pages - This web page does not work on mobile)</h6>
              </div>
            </div>
          </div>
        </div>
      </Parallax>


      {types.map((type, index) => (
        <Parallax key={index} strength={type.strength} blur={{ min: -5, max: 8 }} bgImage={type.picture}>
          <div className='content' id={type.type.split(" ")[type.type.split(" ").length - 1]}>
            <h4 className='mt-2 ms-2'>Harvey Street | DC40052400</h4>
            <div className='container mt-5 bg-white p-2 rounded-3 marginTop'>
              <div className='row'>
                <div className="paddingTitle">
                  <div className='bg-white p-4 rounded-3 shadow border border-gray'>
                    <h1 className='d-flex justify-content-center'>{type.type}</h1>
                  </div>
                </div>
                <div className='col-lg-6 mt-3'>
                  <div className='bg-white p-3 rounded-3 shadow border border-gray'>
                    <h2 className='d-flex justify-content-center'>Information</h2>
                    <h6>{type.type} is {type.definition}. The target audience for {type.type.toLowerCase()} is {type.target}. Locations where {type.type.toLowerCase()} happens are {type.location}.</h6>
                  </div>
                </div>
                <div className='col-lg-6 mt-3'>
                  <div className='bg-white p-3 rounded-3 shadow border border-gray'>
                    <h2 className='d-flex justify-content-center'>{type.type} Providers</h2>
                    <h6>There are Providers for {type.type.toLowerCase()} all across the UK, below you can find a list of a couple of them.</h6>
                    <FluentProvider theme={webLightTheme}>
                      <Accordion multiple collapsible>
                        {type.providers.map((provider, index) => (
                          <AccordionItem value={provider.name} key={index}>
                            <AccordionHeader>{provider.name}</AccordionHeader>
                            <AccordionPanel>
                              <div>{provider.description}</div>
                            </AccordionPanel>
                          </AccordionItem>
                        ))}

                      </Accordion>
                    </FluentProvider>
                  </div>
                </div>
              </div>

              <div className='row mt-2 paddingTitle'>
                <div className='bg-white p-3 rounded-3 shadow border border-gray'>
                  <h2 className='d-flex justify-content-center'>{type.type} Aims & Skills</h2>
                  <FluentProvider theme={webLightTheme}>
                    <Divider className='mt-2 mb-2'>The aims of {type.type.toLowerCase()}</Divider>
                  </FluentProvider>
                  <h6>The aims of {type.type.toLowerCase()} are {type.aims}.</h6>

                  <FluentProvider theme={webLightTheme}>
                    <Divider className='mt-3 mb-2'>Skill you develop and improve through {type.type.toLowerCase()}</Divider>
                  </FluentProvider>
                  <h6>Skills you can develop and improve through {type.type.toLowerCase()} are {type.skills}.</h6>
                </div>

              </div>
            </div>
          </div>
        </Parallax>
      ))}

      <Parallax strength={600} blur={{ min: -5, max: 8 }} bgImage={builtWith}>
        <div className='content' id="References">
          <h4 className='mt-2 ms-2 text-white'>Harvey Street | DC40052400</h4>
          <div className='container mt-5'>
            <div className='d-flex justify-content-center'>
              <div className='bg-white p-3 rounded-3 wx-card marginTop'>
                <h1>References</h1>
                {references.map((reference, index) => (
                  <p key={index}>{reference.author} ({reference.year}). {reference.title}. <a href={reference.url} rel="noreferrer" target="_blank" className='text-primary'>{reference.url?.split("https://")[1] || ""}</a> Retrieved Date {reference.accessed_date}</p>
                ))}

                <p className='mt-5 d-flex fontSizeVerySmall'>Background pictures from <a rel="noreferrer" target="_blank" href='https://pixabay.com/' className='nav-link text-info ms-1'>Pixabay</a>, a free stock image site.</p>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
}

export default App;
