let references = [
    {
        "author": "Large Outdoors",
        "year": "2024",
        "title": "Large Outdoors",
        "url": "https://www.largeoutdoors.com",
        "accessed_date": "27/03/2024"
    },
    {
        "author": "Exodus Adventure Travels",
        "year": "2024",
        "title": "Adventure Holidays | Activity Holidays & Tours | Exodus Adventure Travels",
        "url": "https://www.exodus.co.uk",
        "accessed_date": "27/03/2024"
    },
    {
        "author": "My Expedition Rocks",
        "year": "2024",
        "title": "My Expedition Rocks - Outdoor Adventure Activities in Yorkshire",
        "url": "https://myexpeditionrocks.com",
        "accessed_date": "27/03/2024"
    },
    {
        "author": "Cyclewise",
        "year": "2024",
        "title": "Mountain Bike Courses & Tuition",
        "url": "https://www.britishcycling.org.uk/mtbleadership/article/meetourproviders",
        "accessed_date": "27/03/2024"
    },
    {
        "author": "North Yorkshire Outdoor Learning Service",
        "year": "2024",
        "title": "Home - North Yorkshire Outdoor Learning Service",
        "url": "https://outdoored.co.uk",
        "accessed_date": "27/03/2024"
    },
    {

        "author": "Xscape Yorkshire",
        "year": "2024",
        "title": "Snozone",
        "url": "https://xscapeyorkshire.co.uk/attractions/snozone",
        "accessed_date": "27/03/2024"
    },
    {
        "author": "Lost Earth Adventures",
        "year": "2024",
        "title": "Abseiling Courses and Experiences",
        "url": "https://www.lostearthadventures.co.uk/abseiling",
        "accessed_date": "27/03/2024"
    },
    {
        "author": "Charity Abseils",
        "year": "2024",
        "title": "About Us",
        "url": "https://www.charity-abseils.co.uk/about-us",
        "accessed_date": "27/03/2024"
    },
    {
        "author": "Lost Earth Adventures",
        "year": "2024",
        "title": "Caving Courses and Experiences",
        "url": " https://www.lostearthadventures.co.uk/caving-courses-experience-days",
        "accessed_date": "27/03/2024"
    },
    {
        "author": "Carlton Lodge",
        "year": "2024",
        "title": "Caving Experiences",
        "url": " https://www.carltonlodge.org.uk/package/caving-experiences",
        "accessed_date": "27/03/2024"
    },


]

references = references.sort((a, b) => a.author.localeCompare(b.author));

export default references
