import hiking from './assets/hiking.jpg'
import climbing from './assets/climbing.jpg'
import biking from './assets/biking.jpg'
import skiing from './assets/skiing.jpg'
import caving from './assets/caving.jpg'
import abseiling from './assets/abseiling.jpg'

const types = [
    {
        type: "Hiking",
        definition: "a land-based activity that can be done by yourself, in groups or for college and school trips and uniformed services training",
        target: "anyone who wants to experience the outdoors and get new experiences if they have never been in the middle of nowhere before",
        location: "anywhere with foot access, for example the Peak District or Snowdonia",
        providers: [
            {
                "name": "Large Outdoors",
                "description": "Large Outdoors is a UK-based company that offers trips, challenges and experiences around the UK in places such as the Lake and Peak District."
            },
            {
                "name": "Exodus Travels",
                "description": "Exodus Travels is an international company that offers trips to thousands of locations on all seven continents. They offer guidance and guides for trips to help people explore the great outdoors."
            },
        ],
        aims: "for both recreation and sport but the primary goal of this type of activity is to have fun, develop new skills and meet new people",
        skills: "your coordination skills, your navigate and map reading skills and you may be able to get a qualification allowing you to be a guide for others",
        picture: hiking,
        strength: 600
    },
    {
        type: "Climbing",
        definition: "a land-based activity that can be done by yourself, in groups, for college & school trips, and for certification training for the uniformed services or climbing qualifications given out by the BMC",
        target: "a specific group of people who are okay at stepping outside their comfort zone, such as adrenaline junkies, people wanting new experiences or even members of the uniformed services or civilians who need or want a climbing qualification",
        location: "any area with a cliff face that is high enough for the climber. A common place for climbing in the UK would be the Peak District",
        providers: [
            {
                "name": "My Expedition Rocks",
                "description": "My Expedition Rocks is a Yorkshire-based company that specializes in rock climbing and other mountain activities in the Yorkshire Dales. They offer courses for first aid and rock climbing training. They also offer experiences such as their adventure days."
            },
            {
                "name": "Carnegie Great Outdoors ",
                "description": "Carnegie Great Outdoors is a Leeds-based company which is located inside of Leeds Beckett University. They offer adventure activities, outdoor education and qualifications and expeditions around the UK and around the world."
            }
        ],
        aims: "for both recreation and sport but the primary goal of this type of activity is to have fun, develop new skills and meet new people",
        skills: "your teamwork skills by improving communication and coordination between each other. It also helps you develop other skills such as rope handling, knot tying and climbing techniques",
        picture: climbing,
        strength: 600
    },
    {
        type: "Mountain Biking",
        definition: "a land-based activity that can be done by yourself, in groups, for college trips, and also training for a biking certificate in most uniformed services such as the fire service, police service and Royal Army",
        target: "anyone who wants to take their biking experience to the next level or someone who wants to have fun on a bike. The target audience may also be members of the uniformed services wanting to get some training before their qualification/certification training",
        location: "any offroad location that allows access to people on bicycles, for example, the Peak or Lake District",
        providers: [
            {
                "name": "Cyclewise",
                "description": "Cyclewise is a UK-based company which offers opportunities to rent and buy bicycles. They also offer courses and private tuitions to help improve a person's or group biking and offroading abilities."
            },
            {
                "name": "North Yorkshire Outdoor Learning Service",
                "description": "The North Yorkshire Outdoor Learning Service is a UK-based company located in North Yorkshire. They offer a range of experiences and courses, such as caving, high rope, river scrambling, and biking. The website focuses on primary and secondary schools but looks to have experiences for over 16-year-olds."
            }
        ],
        aims: "for both recreation and sport but the primary goal of this type of activity is to have fun, develop new skills and meet new people",
        skills: "Skills you can develop and improve through mountain biking are your teamwork skills by improving communication and coordination among riders. It also helps you develop other skills such as your balance, agility and physical fitness",
        picture: biking,
        strength: 600
    },
    {
        type: "Skiing",
        definition: "a land-based activity that can be done by yourself, in groups, and for college and school trips. Skiing can also give training to the royal army for operating in winter conditions in countries such as Sweden or Norway",
        target: "anyone who wants to step outside their comfort zone to try new experiences to see if they would want to do it again. People also ski for sport to train for competitions around the world",
        location: "any snowy area that is a downhill slop, or any area designed for skiing such as a ski resort",
        providers: [
            {
                "name": "Xscape Yorkshire",
                "description": "Xscape Yorkshire offers indoor skiing and snowboarding experiences and training on real snow in South Yorkshire."
            },
            {
                "name": "The Snow Centre",
                "description": "The Snow Centre located in London offers indoor skiing and snowboarding experiences on a 160-metre slope which is the largest indoor skiing slope."
            }
        ],
        aims: "for both recreation and sport but the primary goal of this type of activity is to have fun, develop new skills and meet new people",
        skills: "your teamwork skills through collaboration and communication among participants, it also helps you develop other skills such as improving your balance and coordination skills",
        picture: skiing,
        strength: 300
    },
    {
        type: "Abseiling",
        definition: "a land-based activity that can be done by yourself, in groups or for college & school trip. Abseiling can also be done for certification training within the Royal Army, fire service and some areas of the police service",
        target: "a specific group of people who like to step outside their comfort zone and try new things to see if they like it. This could be a group of people on an expedition trying as many things as they can or adrenaline junkies who just want a high of adrenaline. It is also targeted at people in the uniformed services who want to train outside the service before getting a certification",
        location: "any outdoors areas where there are large cliff faces or gaps high enough for the abseiler, for example, the Peak District and other areas with a large number of mountains",
        providers: [
            {
                "name": "Lost Earth Adventures",
                "description": "Lost Earth Adventures is a UK-based company that offers abseiling training and experiences in Yorkshire Dales, Peak and Lake District."
            },
            {
                "name": "Charity Abseils",
                "description": "Charity Abseils is one of the UK's leading outdoor adventure and charity abseil providers, they offer "
            }
        ],
        aims: "for both recreation and sport but the primary goal of this type of activity is to have fun, develop new skills and meet new people",
        skills: "your teamwork skills by improving communication and coordination between each other. It also helps you develop other skills such as rope handling, knot tying and rappelling techniques",
        picture: abseiling,
        strength: 200
    },
    {
        type: "Caving",
        definition: "a land-based activity that can be done by yourself, in groups, and for college & school trips. Caving can also be done for certification training in the fire service and Royal army",
        target: "a specific group of people who wish to step out of their comfort zone and try new things, this could also be for adrenaline junkies who want a high by squishing themselves in a gap smaller than them and seeing if they can get out",
        location: "any outdoor locations where there are caves, such as the Lake District",
        providers: [
            {
                "name": "Lost Earth Adventures",
                "description": "Lost Earth Adventures is a UK-based company that offers caving training and experiences in Yorkshire and the Peak District."
            },
            {
                "name": "Carlton Lodge",
                "description": "Carlton Lodge is a UK based company located in Thirsk. They offer caving experiences for groups on weekends in caves around the site, they offer three levels of caving which are, introductory caving, intermediate caving, and advanced caving."
            }
        ],
        aims: "for both recreation and sport but the primary goal of this type of activity is to have fun, develop new skills and meet new people",
        skills: "your teamwork skills through encouraging communication and cooperation between each other. It also helps you develop other skills, such as navigation, rope walking and basic caving safety standards that all cavers should follow",
        picture: caving,
        strength: 100
    }
]

export default types